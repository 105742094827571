import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./component/Dashboard/Dasboard.css";
import AdminLogin from "./component/admin-login/AdminLogin";

const Invoice = lazy(() => import("./component/Invoice/MasterInvoice"));
const MasterInvoice = lazy(() => import("./component/Invoice/MasterInvoice"));

const VendorProducts = lazy(() =>
  import("./pages/VendorProduct/VendorProducts")
);
const AddVendorProducts = lazy(() =>
  import("./pages/VendorProduct/AddProducts/AddVendorProducts")
);
const EditVendorProducts = lazy(() =>
  import("./pages/VendorProduct/EditProducts/EditVendorProducts")
);
const ChangePassword = lazy(() => import("./component/Profile/ChangePassword"));

const TimeSlot = lazy(() =>
  import("./pages/DeliverySchedule/TimeSlot/TimeSlot")
);
const ClosingHours = lazy(() =>
  import("./pages/DeliverySchedule/ClosingHours/ClosingHours")
);

// lazy loading for this pages only
const Home = lazy(() => import("./pages/Home/Home"));

const AllOrder = lazy(() => import("./pages/Orders/AllOrders/AllOrder"));

const EditProfile = lazy(() => import("./component/Profile/EditProfile"));

// vendor
const VendorList = lazy(() => import("./pages/Vendor/VendorList/VendorList"));
const Add_Vendor = lazy(() => import("./pages/Vendor/AddVendor/Add_Vendor"));
const Edit_Vendor = lazy(() => import("./pages/Vendor/EditVendor/Edit_Vendor"));
const Leads = lazy(() => import("./pages/Vendor/Leads/Leads"));
const LeadDetails = lazy(() => import("./pages/Vendor/Leads/LeadDetails"));

const WalletWithdrawal = lazy(() =>
  import("./component/walletWithdrawal/WalletWithdrawal")
);
const StoreImages = lazy(() =>
  import("./pages/Vendor/StoreImages/StoreImages")
);

const SellerPlans = lazy(() =>
  import("./pages/Vendor/sellerPlans/SellerPlans")
);
const SellerHistory = lazy(() =>
  import("./pages/Vendor/sellerPlans/SellerHistory")
);

const AddBulkProduct = lazy(() =>
  import("./pages/Products/addBulkProduct/AddBulkProduct")
);

const ProductTab = lazy(() =>
  import("./pages/Products/ProductAttributeTabs/ProductTab")
);

const Routing = () => {
  return (
    <Suspense
      fallback={
        <div className="lazy-loading-main d-flex justify-content-center align-items-center">
          {/* <img
            src={require("./component/assets/images/latest-logo.png")}
            alt="loading"
            className="img-fluid"
          /> */}
          {/* <div className="loader"></div> */}
        </div>
      }
    >
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/login" element={<AdminLogin />}></Route>
        <Route path="/orders" element={<AllOrder />}></Route>

        {/* Vendor */}
        <Route path="/vendor_list" element={<VendorList />}></Route>
        <Route
          path="/seller/product/bulk/:vendorId"
          element={<AddBulkProduct />}
        ></Route>
        <Route path="/add_vendor" element={<Add_Vendor />}></Route>
        <Route path="/edit_vendor/:id" element={<Edit_Vendor />}></Route>
        <Route path="/products_tab/:id" element={<ProductTab />}></Route>
        <Route path="/leads" element={<Leads />}></Route>
        <Route path="/leads/:id" element={<LeadDetails />}></Route>
        <Route path="/seller/storeImages" element={<StoreImages />}></Route>
        {/* vendor product */}
        <Route path="/seller_products" element={<VendorProducts />}></Route>
        <Route path="/plans/history" element={<SellerHistory />}></Route>
        <Route path="/plans" element={<SellerPlans />}></Route>
        <Route path="/wallet/withdrawal" element={<WalletWithdrawal />}></Route>
        <Route
          path="/add_seller_products/:id"
          element={<AddVendorProducts />}
        ></Route>
        <Route
          path="/edit_seller_product/:id"
          element={<EditVendorProducts />}
        ></Route>
        <Route path="/edit_profile" element={<EditProfile />}></Route>
        <Route path="/change_password" element={<ChangePassword />}></Route>
        {/* order details */}
        <Route path="/order_details/:id" element={<Invoice />}></Route>
        <Route path="/master_invoice/:id" element={<MasterInvoice />}></Route>

        {/* delivery schedule hours */}
        <Route path="/time_slot" element={<TimeSlot />}></Route>
        <Route path="/closing_hours" element={<ClosingHours />}></Route>
      </Routes>
    </Suspense>
  );
};

export default Routing;
